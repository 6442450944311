import React, { FC, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import userAtom from '../atoms/userAtom';
import { getCollections } from '../persistence/CollectionPersistence';
import tokenAtom from '../atoms/tokenAtom';
import { ICollection } from '../interfaces/ICollection';
import { cloneDeep } from 'lodash';
import { updateUser } from '../persistence/UserPersistence';
import notificationAtom from '../atoms/notificationAtom';
import { NotificationType } from '../interfaces/INotification';
import CollectionCheckbox from './CollectionCheckbox';

const MyCollections: FC = () => {
  const [user, setUser] = useRecoilState(userAtom);
  const token = useRecoilValue(tokenAtom);
  const [collectionList, setCollectionList] = useState<ICollection[]>([]);
  const [ownedCollections, setOwnedCollections] = useState<string[]>(user.collections ?? []);
  const setNotification = useSetRecoilState(notificationAtom);

  useEffect(() => {
    getCollections(token).then(list => {
      setCollectionList(list);
    }).catch(err => {
      setNotification({
        type: NotificationType.FAILURE,
        isOpen: true,
        title: 'Error loading collections',
        message: err,
      });
    });
  }, [token, setNotification, setCollectionList]);

  const handleClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.id;
    const updatedCollections = [...ownedCollections];
    if (updatedCollections.includes(id)) {
      // remove it
      setOwnedCollections(updatedCollections.filter(collection => collection! !== id));
    } else {
      // add it
      updatedCollections.push(id);
      setOwnedCollections(updatedCollections);
    }
  }

  const renderCollectionList = () => {
    return collectionList.map(collection => (
      <CollectionCheckbox
        name={collection.name}
        isChecked={ownedCollections.includes(collection.name!)}
        onChange={handleClick}
        key={collection.id}
      />
    ));
  }

  const handleSave = () => {
    const updatedUser = cloneDeep(user);
    updatedUser.collections = ownedCollections;
    updateUser(updatedUser, token).then(savedUser => {
      setUser(savedUser);
      setNotification({
        type: NotificationType.SUCCESS,
        isOpen: true,
        title: 'Success',
        message: 'Collections updated successfully'
      });
    }).catch(err => {
      setNotification({
        type: NotificationType.FAILURE,
        isOpen: true,
        title: 'Error saving',
        message: err,
      });
    });
  }

  return (
    <div>
      <h1>My Collections</h1>
      <div className='my-collections-list'>
        {renderCollectionList()}
      </div>
      <button onClick={handleSave}>Save</button>
    </div>
  );
}

export default MyCollections;
