import React, { FC } from 'react';
import { ReactComponent as ListIcon } from '../static/icons/listine-dots.svg';
import { ReactComponent as PlusIcon } from '../static/icons/plus.svg';
import { ReactComponent as MeepleIcon } from '../static/icons/meeple.svg';
import { ReactComponent as LogIcon} from '../static/icons/archive-register.svg';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const MainFooter: FC = () => {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return (<footer className='main-footer' /> );
  }

  return (
    <footer className='main-footer'>
      <Link to="/lists">
        <ListIcon />
        Lists
      </Link>
      <Link to="/new-game">
        <PlusIcon />
        New Game
      </Link>
      <Link to="/log-play">
        <LogIcon />
        Log Play
      </Link>
      <Link to="/profile">
        <MeepleIcon />
        Profile
      </Link>
    </footer>
  );
}

export default MainFooter;
