import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../static/icons/simple-left.svg';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
import { useAuth0 } from '@auth0/auth0-react';

const MainHeader: FC = () => {
  const history = useHistory();
  const { isAuthenticated } = useAuth0();

  return (
    <header className="main-header">
      {isAuthenticated ?
        <button onClick={() => history.goBack()}>
          <BackIcon/>
        </button>
        : <span/>
      }
      <h1>Randomizer</h1>
      {isAuthenticated ? <LogoutButton/> : <LoginButton/>}
    </header>
  );
};

export default MainHeader;
