import { atom } from 'recoil';

const blankPermissions: string[] = [];

const permissionAtom = atom({
  key: 'permissionState',
  default: blankPermissions,
});

export default permissionAtom;
