import { ICollection } from '../interfaces/ICollection';
import { makeAuthHeaders } from './AuthHelper';
import { getApiUrl } from '../utilities/PersistenceUtilities';

const URL = `${getApiUrl()}/collections`;

export const getCollections = async (token: string): Promise<ICollection[]> => {
  const response = await fetch(URL, {
    headers: makeAuthHeaders(token),
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
};

export const getCollection = async (id: string, token: string): Promise<ICollection> => {
  const response = await fetch(`${URL}/${id}`, {
    headers: makeAuthHeaders(token),
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
};

export const createCollection = async (collection: ICollection, token: string): Promise<ICollection> => {
  const response = await fetch(URL, {
    method: "POST",
    body: JSON.stringify(collection),
    headers: makeAuthHeaders(token)
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
};

export const updateCollection = async (collection: ICollection, token: string): Promise<ICollection> => {
  const response = await fetch(`${URL}/${collection.id}`, {
    method: "PUT",
    body: JSON.stringify(collection),
    headers: makeAuthHeaders(token),
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
};

export const deleteCollection = async (id: string, token: string) => {
  const response = await fetch(`${URL}/${id}`, {
    headers: makeAuthHeaders(token),
    method: "DELETE",
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}
