import React, { FC, useEffect, useState } from 'react';
import { ICollection } from '../interfaces/ICollection';
import { getCollections } from '../persistence/CollectionPersistence';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import tokenAtom from '../atoms/tokenAtom';
import { Link } from 'react-router-dom';
import { NotificationType } from '../interfaces/INotification';
import notificationAtom from '../atoms/notificationAtom';

const CollectionList: FC = () => {
  const [collections, setCollections] = useState<ICollection[]>([]);
  const token = useRecoilValue(tokenAtom);
  const setNotification = useSetRecoilState(notificationAtom);

  useEffect(() => {
    getCollections(token).then(list => {
      setCollections(list);
    }).catch(err => {
      setNotification({
        type: NotificationType.FAILURE,
        isOpen: true,
        title: 'Error getting Collections',
        message: err.message,
      });
    });
  }, [token, setCollections, setNotification]);

  const renderCollections = () => collections.map(collection => (
    <li key={collection.id!}><Link to={`/collections/${collection.id!}`}>{collection.name}</Link></li>
  ));

  return (
    <div className="collection-list">
      <ul>
        {renderCollections()}
      </ul>
    </div>
  );
};

export default CollectionList;
