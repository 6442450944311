export interface ILocation {
  id?: string;
  name: string;
  endOfTurn: string;
  collection: string;
}

export const blankLocation: ILocation = {
  id: '',
  name: '',
  endOfTurn: '',
  collection: '',
}
