import { makeAuthHeaders } from './AuthHelper';
import { IChallenge } from '../interfaces/IChallenge';
import { getApiUrl } from '../utilities/PersistenceUtilities';

const URL = `${getApiUrl()}/difficulties`;

export const getDifficulties = async (token: string): Promise<IChallenge[]> => {
  const response = await fetch(URL, {
    headers: makeAuthHeaders(token),
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
};

export const getDifficulty = async (id: string, token: string): Promise<IChallenge> => {
  const response = await fetch(`${URL}/${id}`, {
    headers: makeAuthHeaders(token),
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}

export const createDifficulty = async (challenge: IChallenge, token: string): Promise<IChallenge> => {
  const response = await fetch(URL, {
    headers: makeAuthHeaders(token),
    method: "POST",
    body: JSON.stringify(challenge),
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}

export const updateDifficulty = async (challenge: IChallenge, token: string): Promise<IChallenge> => {
  const response = await fetch(`${URL}/${challenge.id!}`, {
    headers: makeAuthHeaders(token),
    method: "PUT",
    body: JSON.stringify(challenge),
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}

export const deleteDifficulty = async (id: string, token: string) => {
  const response = await fetch(`${URL}/${id}`, {
    headers: makeAuthHeaders(token),
    method: "DELETE",
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}

export const getRandomDifficulty = async (token: string): Promise<IChallenge[]> => {
  const response = await fetch(`${URL}/random`, {
    headers: makeAuthHeaders(token),
    method: "GET",
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}

