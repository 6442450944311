import React, { FC } from 'react';
import { IVillainThreat } from '../interfaces/IVillain';
import { isEmpty } from 'lodash';
import { generateRandomString } from '../utilities/stringUtils';
import ToggleSwitch from '../common/ToggleSwitch';

const VillainThreatSection: FC<IVillainThreatProps> = ({threat, onThreatChanged}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const updatedThreat = {...threat}


    if (isEmpty(updatedThreat.id)) {
      updatedThreat.id = generateRandomString();
    }

    switch (e.target.id) {
    case 'name':
      updatedThreat.name = e.target.value;
      break;
    case 'text':
      updatedThreat.text = e.target.value;
      break;
    case 'count':
      updatedThreat.count = parseInt(e.target.value);
      break;
    case 'health':
      updatedThreat.health = parseInt(e.target.value);
      break;
    case 'hasBam':
      updatedThreat.hasBam = !updatedThreat.hasBam;
      break;
    }

    onThreatChanged(updatedThreat);
  }

  return (
    <div className="villain-threat-section">
      <label>Name:</label>
      <input type="text" id="name" value={threat.name} onChange={handleChange}/>
      <label>Text:</label>
      <textarea id="text" value={threat.text} onChange={handleChange} />
      <label>Count:</label>
      <input id="count" value={threat.count} onChange={handleChange} />
      <label>Health:</label>
      <input type="number" id="health" value={threat.health} onChange={handleChange} />
      <label>Activates BAM:</label>
      <ToggleSwitch checked={threat.hasBam} onChange={handleChange} name="hasBam" />
    </div>
  );
}

export default VillainThreatSection;

interface IVillainThreatProps {
  threat: IVillainThreat;
  onThreatChanged: (threat: IVillainThreat) => void;
}
