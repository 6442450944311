import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from '../home/Home';
import Lists from '../home/Lists';
import Admin from '../admin/Admin';
import HeroForm from '../admin/HeroForm';
import Profile from '../profile/Profile';
import HeroList from '../heroes/HeroList';
import Hero from '../heroes/Hero';
import Authorized from './Authorized';
import Logout from './Logout';
import CollectionForm from '../admin/CollectionForm';
import CollectionList from '../collections/CollectionList';
import VillainForm from '../admin/VillainForm';
import LocationForm from '../admin/LocationForm';
import ChallengeForm from '../admin/ChallengeForm';
import MyCollections from '../profile/MyCollections';
import VillainList from '../villains/VillainList';
import Villain from '../villains/Villain';
import LocationList from '../locations/LocationList';
import Location from '../locations/Location';
import ChallengeList from '../challenges/ChallengeList';
import Challenge from '../challenges/Challenge';
import Collection from '../collections/Collection';
import NewGame from '../game/NewGame';
import ProtectedRoute from './ProtectedRoute';
import AdminProtectedRoute from './AdminProtectedRoute';
import DifficultyForm from '../admin/DifficultyForm';
import DifficultyList from '../difficulties/DifficultyList';
import Difficulty from '../difficulties/Difficulty';
import LogPlay from '../logPlay/LogPlay';

const Routes: FC = () => {
  return (
    <Switch>
      {/* Base Routes */}
      <Route path="/" exact component={Home}/>
      <Route path="/authorized" exact component={Authorized}/>
      <Route path="/logout" exact component={Logout}/>
      <ProtectedRoute path="/lists" exact component={Lists}/>

      {/* Profile Routes */}
      <ProtectedRoute component={Profile} path="/profile" exact/>
      <ProtectedRoute component={MyCollections} path="/profile/collections" exact/>

      { /* Admin Routes */}
      <AdminProtectedRoute component={Admin} path="/admin" exact/>
      <AdminProtectedRoute component={HeroForm} path="/admin/hero-form" exact/>
      <AdminProtectedRoute component={CollectionForm} path="/admin/collection-form" exact/>
      <AdminProtectedRoute component={VillainForm} path="/admin/villain-form" exact/>
      <AdminProtectedRoute component={LocationForm} path="/admin/location-form" exact/>
      <AdminProtectedRoute component={ChallengeForm} path="/admin/challenge-form" exact/>
      <AdminProtectedRoute component={DifficultyForm} path="/admin/difficulty-form" exact/>

      {/* Hero Routes */}
      <ProtectedRoute component={HeroList} path="/heroes" exact/>
      <ProtectedRoute component={Hero} path="/heroes/:id" exact/>

      {/* Villain Routes */}
      <ProtectedRoute component={VillainList} path="/villains" exact/>
      <ProtectedRoute component={Villain} path="/villains/:id" exact/>

      {/* Location Routes */}
      <ProtectedRoute component={LocationList} path="/locations" exact/>
      <ProtectedRoute component={Location} path="/locations/:id" exact/>

      {/* Challenge Routes */}
      <ProtectedRoute component={ChallengeList} path="/challenges" exact/>
      <ProtectedRoute component={Challenge} path="/challenges/:id" exact/>

      {/* Collection Routes */}
      <ProtectedRoute component={CollectionList} path="/collections" exact/>
      <ProtectedRoute component={Collection} path="/collections/:id" exact/>

      {/* Difficulty Routes */}
      <ProtectedRoute component={DifficultyList} path="/difficulties" exact/>
      <ProtectedRoute component={Difficulty} path="/difficulties/:id" exact/>

      {/* Game Routes */}
      <ProtectedRoute component={NewGame} path="/new-game" exact/>

      {/* Log Play Routes */}
      <ProtectedRoute component={LogPlay} path="/log-play" exact/>
    </Switch>
  );
};

export default Routes;
