import { MouseEvent, FC } from 'react';
import { ReactComponent as RecycleIcon } from '../static/icons/standard_recycle.svg';
const RecycleToggle: FC<IRecycleToggleProps> = (
  {
    isChecked,
    onClick,
  }
) => {
  const getClassName = () => {
    if (isChecked) {
      return 'recycle-on';
    }

    return 'recycle-off';
  }

  return (
    <button className={`recycle-toggle ${getClassName()}`} onClick={onClick}>
      <RecycleIcon/>
    </button>
  );
};

export default RecycleToggle;

interface IRecycleToggleProps {
  isChecked: boolean;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}
