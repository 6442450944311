import React, { FC, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import tokenAtom from '../atoms/tokenAtom';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import userAtom from '../atoms/userAtom';
import { getUserFromApi } from '../persistence/UserPersistence';
import { isEmpty } from 'lodash';
import jwtDecode from 'jwt-decode';
import permissionsAtom from '../atoms/permissionAtom';

interface IClaims {
  iss: string;
  sub: string;
  aud: string[];
  iat: number;
  exp: number;
  azp: string;
  scope: string;
  permissions: string[]
}

const Authorized: FC = () => {
  const setToken = useSetRecoilState(tokenAtom);
  const setUser = useSetRecoilState(userAtom);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  const setPermissions = useSetRecoilState(permissionsAtom);

  useEffect(() => {
    const getToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: "https://randomizer.tstark2.us",
          scope: "user:standard",
        });

        setToken(accessToken);
        return accessToken;
      } catch (err) {
        console.error(err);
      }
    }

    if (isAuthenticated) {
      getToken().then(async (token) => {
        const loggedIn = await getUserFromApi(token!)
        if (isEmpty(loggedIn.collections)) loggedIn.collections = [];
        if (isEmpty(loggedIn.stats.heroesPlayed)) loggedIn.stats.heroesPlayed = [];
        if (isEmpty(loggedIn.stats.villainsPlayed)) loggedIn.stats.villainsPlayed = [];
        setUser(loggedIn);
        const claims = jwtDecode<IClaims>(token!);
        setPermissions(claims.permissions);
        history.push('/profile');
      }).catch(() => {});
    }
  }, [getAccessTokenSilently, isAuthenticated, setToken, history, setUser, setPermissions]);

  return (
    <div>Logging you in...</div>
  );
};

export default Authorized;
