import { FC, useCallback, useState } from 'react';
import ToggleSwitch from '../common/ToggleSwitch';
import { getRandomChallenge } from '../persistence/ChallengePersistence';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import tokenAtom from '../atoms/tokenAtom';
import { NotificationType } from '../interfaces/INotification';
import notificationAtom from '../atoms/notificationAtom';
import { ReactComponent as RandomIcon } from '../static/icons/random.svg';
import { blankChallenge, IChallenge } from '../interfaces/IChallenge';

const SelectChallenge: FC<ISelectChallengeProps> = ({ challenge, updateChallenge }) => {
  const token = useRecoilValue(tokenAtom);
  const setNotification = useSetRecoilState(notificationAtom);
  const [useRandomChallenge, setUseRandomChallenge] = useState(false);

  const getChallenge = useCallback(() => {
    getRandomChallenge(token).then(randomChallenge => {
      updateChallenge(randomChallenge[0]);
    }).catch(err => {
      setNotification({
        type: NotificationType.FAILURE,
        isOpen: true,
        title: 'Error getting Challenge',
        message: err.message
      });
    });
  }, [token, updateChallenge, setNotification]);

  const handleClick = () => {
    getChallenge();
  };

  const renderChallenge = () => {
    if (useRandomChallenge) {
      return (
        <div className="select-challenge__challenge">
          <h3>{challenge.name}</h3>
          <p>{challenge.setup}</p>
        </div>
      );
    }
    return undefined;
  };

  const handleChange = () => {
    if (!useRandomChallenge) {
      setUseRandomChallenge(true);
      getChallenge();
    } else {
      setUseRandomChallenge(false);
      updateChallenge(blankChallenge);
    }
  };

  const renderButton = () => {
    if (useRandomChallenge) {
      return (
        <div className="select-challenge__buttons">
          <button onClick={handleClick}><RandomIcon/></button>
        </div>
      );
    }
    return undefined;
  };

  return (
    <div className="select-challenge">
      <h1>Select a Challenge</h1>
      <div className="select-challenge__toggle">
        <label htmlFor="useChallenge">Random Challenge:</label>
        <ToggleSwitch checked={useRandomChallenge} onChange={handleChange} name="useChallenge"/>
      </div>
      {renderChallenge()}
      {renderButton()}
    </div>
  );
};

export default SelectChallenge;

interface ISelectChallengeProps {
  challenge: IChallenge;
  updateChallenge: (challenge: IChallenge) => void;
}
