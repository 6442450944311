import { FC } from 'react';

const FourOhFour: FC = () => (
  <div className="fourOhFour">
    <h1>404</h1>
    <img src="https://cdn.tstark2.us/randomizer/resources/captainAmericaDetention.png" alt="Captain America Detention Meme"/>
    <p>So, you're looking for a page that doesn't exist...</p>
  </div>
);

export default FourOhFour
