import { ChangeEvent, FC, useState } from 'react';
import { CharacterType } from '../interfaces/CharacterType';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import notificationAtom from '../atoms/notificationAtom';
import tokenAtom from '../atoms/tokenAtom';
import { searchForHeroes } from '../persistence/HeroPersistence';
import { notifyFailure } from '../utilities/NotificationUtilities';
import { searchForVillains } from '../persistence/VillainPersistence';
import { isEmpty } from 'lodash';

const CharacterSearch: FC<ICharacterSearchProps> = (
  {
    characterType,
    character,
    updateCharacter,
  }
) => {
  const [term, setTerm] = useState<string>('');
  const [results, setResults] = useState<string[]>([]);
  const setNotification = useSetRecoilState(notificationAtom);
  const token = useRecoilValue(tokenAtom);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const currentTerm = e.target.value;
    setTerm(currentTerm);
    if (!isEmpty(currentTerm)) {
      if (characterType === CharacterType.HERO) {
        searchForHeroes(currentTerm, token).then(heroes => {
          if (!isEmpty(heroes)) {
            setResults(heroes.map(hero => hero.name));
          } else {
            setResults(['No Results']);
          }
        }).catch(err => {
          setNotification(notifyFailure('Error searching for heroes', err.message));
        });
      } else {
        searchForVillains(currentTerm, token).then(villains => {
          if(!isEmpty(villains)) {
            setResults(villains.map(villain => villain.name));
          } else {
            setResults(['No Results']);
          }
        }).catch(err => {
          setNotification(notifyFailure('Error searching for villains', err.message));
        });
      }
    } else {
      setResults([]);
    }
  };

  const handleResultClick = (e: ChangeEvent<HTMLInputElement>) => {
    updateCharacter(e.target.id);
    setTerm(e.target.id);
    setResults([]);
  }

  const renderResults = () => (
    results.map(result => (
      <label key={result} className="character-search__result">
        <input type="radio" id={result} checked={character === result} onChange={handleResultClick}/>
        <span>{result}</span>
      </label>
    )));

  return (
    <div className="character-search">
      <input
        type="text"
        placeholder={`Search for a ${characterType === CharacterType.HERO ? 'Hero' : 'Villain'}`}
        value={term}
        onChange={handleChange}
      />
      <div className={`character-search__results ${isEmpty(results) ? '' : 'open'}`}>
        {renderResults()}
      </div>
    </div>
  );
};

export default CharacterSearch;

interface ICharacterSearchProps {
  characterType: CharacterType;
  character: string;
  updateCharacter: (character: string) => void
}
