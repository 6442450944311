export enum NotificationType {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  WARNING = 'WARNING',
  NONE = 'NONE',
}

export interface INotification {
  isOpen: boolean;
  type: NotificationType;
  title: string;
  message: string;
}

export const blankNotification: INotification = {
  isOpen: false,
  type: NotificationType.NONE,
  title: '',
  message: '',
}
