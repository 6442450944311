import React, { FC } from 'react';
import { convertToFilename } from '../utilities/stringUtils';

const CollectionCheckbox: FC<ICollectionCheckboxProps> = ({name, isChecked, onChange}) => {
  return (
    <label className={`collection-checkbox ${isChecked ? 'checked' : ''}`}>
      <input type="checkbox" checked={isChecked} value={name} onChange={onChange} id={name} />
      <img src={`https://cdn.tstark2.us/randomizer/collections/${convertToFilename(name)}.jpeg`} alt={name} />
      <p>{name}</p>
    </label>
  );
}

export default CollectionCheckbox;

interface ICollectionCheckboxProps {
  name: string;
  isChecked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
