import React, { FC, useEffect, useState } from 'react';
import { IChallenge } from '../interfaces/IChallenge';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import tokenAtom from '../atoms/tokenAtom';
import { getChallenges } from '../persistence/ChallengePersistence';
import { Link } from 'react-router-dom';
import notificationAtom from '../atoms/notificationAtom';
import { NotificationType } from '../interfaces/INotification';

const ChallengeList: FC = () => {
  const [challenges, setChallenges] = useState<IChallenge[]>([]);
  const token = useRecoilValue(tokenAtom);
  const setNotification = useSetRecoilState(notificationAtom);

  useEffect(() => {
    getChallenges(token).then(list => {
      setChallenges(list);
    }).catch(err => {
      setNotification({
        type: NotificationType.FAILURE,
        isOpen: true,
        title: 'Error getting Challenges',
        message: err.message
      });
    });
  }, [token, setChallenges, setNotification]);

  return (
    <div className="challenge-list">
      <ul>
        {challenges.map(challenge =>
          <li key={challenge.id}>
            <Link to={`/challenges/${challenge.id}`}>{challenge.name}</Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default ChallengeList;
