import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import IPathParams from '../interfaces/IPathParams';
import { blankChallenge, IChallenge } from '../interfaces/IChallenge';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import tokenAtom from '../atoms/tokenAtom';
import { getDifficulty } from '../persistence/DifficultyPersistence';
import notificationAtom from '../atoms/notificationAtom';
import { NotificationType } from '../interfaces/INotification';

const Difficulty: FC = () => {
  const { id } = useParams<IPathParams>();
  const [difficulty, setDifficulty] = useState<IChallenge>(blankChallenge);
  const token = useRecoilValue(tokenAtom);
  const setNotification = useSetRecoilState(notificationAtom);

  useEffect(() => {
    getDifficulty(id, token).then(fetched => {
      setDifficulty(fetched);
    }).catch(err => {
      setNotification({
        type: NotificationType.FAILURE,
        isOpen: true,
        title: 'Error getting Difficulty',
        message: err.message
      });
    });
  }, [id, token, setDifficulty, setNotification]);

  return (
    <div className="difficulty-page">
      <h2>{difficulty.name}</h2>
      <h3>Setup:</h3>
      <p>{difficulty.setup}</p>
      <h3>Collection:</h3>
      <p>{difficulty.collection}</p>
    </div>
  );
};

export default Difficulty;
