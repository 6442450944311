import React, { FC } from 'react';
import { Link } from 'react-router-dom';

const Admin: FC = () => {
  return (
    <div className="admin-page">
      <h1>Admin Page</h1>
      <ul>
        <li><Link to="/admin/hero-form">Add a Hero</Link></li>
        <li><Link to="/admin/collection-form">Add a Collection</Link></li>
        <li><Link to="/admin/villain-form">Add a Villain</Link></li>
        <li><Link to="/admin/challenge-form">Add a Challenge</Link></li>
        <li><Link to="/admin/location-form">Add a Location</Link></li>
        <li><Link to="/admin/difficulty-form">Add a Difficulty</Link></li>
      </ul>
    </div>
  );
};

export default Admin;
