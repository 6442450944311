import { FC, useCallback, useState } from 'react';
import ToggleSwitch from '../common/ToggleSwitch';
import { ReactComponent as RandomIcon } from '../static/icons/random.svg';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import tokenAtom from '../atoms/tokenAtom';
import notificationAtom from '../atoms/notificationAtom';
import { getRandomDifficulty } from '../persistence/DifficultyPersistence';
import { NotificationType } from '../interfaces/INotification';
import { blankChallenge, IChallenge } from '../interfaces/IChallenge';

const SelectDifficulty: FC<ISelectDifficultyProps> = ({ difficulty, updateDifficulty }) => {
  const token = useRecoilValue(tokenAtom);
  const setNotification = useSetRecoilState(notificationAtom);
  const [useRandomDifficulty, setUseRandomDifficulty] = useState(false);

  const handleChange = () => {
    if (!useRandomDifficulty) {
      setUseRandomDifficulty(true);
      getDifficulty();
    } else {
      setUseRandomDifficulty(false);
      updateDifficulty(blankChallenge);
    }
  };

  const getDifficulty = useCallback(() => {
    getRandomDifficulty(token).then(fetched => {
      updateDifficulty(fetched[0]);
    }).catch(err => {
      setNotification({
        type: NotificationType.FAILURE,
        isOpen: true,
        title: 'Error getting Difficulty Level',
        message: err.message,
      });
    });
  }, [updateDifficulty, token, setNotification]);

  const handleClick = () => {
    getDifficulty();
  };

  const renderDifficulty = () => {
    if (useRandomDifficulty) {
      return (
        <div className="select-difficulty__difficulty">
          <h3>{difficulty.name}</h3>
          <p>{difficulty.setup}</p>
        </div>
      );
    }
    return undefined;
  };

  const renderButton = () => {
    if (useRandomDifficulty) {
      return (
        <div className="select-difficulty__buttons">
          <button onClick={handleClick}><RandomIcon/></button>
        </div>
      );
    }
    return undefined;
  }

  return (
    <div className="select-difficulty">
      <h1>Select a Difficulty Level</h1>
      <div className="select-difficulty__toggle">
        <label htmlFor="useDifficulty">Random Difficulty Level:</label>
        <ToggleSwitch checked={useRandomDifficulty} onChange={handleChange} name="useDifficulty"/>
      </div>
      {renderDifficulty()}
      {renderButton()}
    </div>
  );
};

export default SelectDifficulty;

interface ISelectDifficultyProps {
  difficulty: IChallenge;
  updateDifficulty: (difficulty: IChallenge) => void;
}
