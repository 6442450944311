import { makeAuthHeaders } from './AuthHelper';
import { IVillain } from '../interfaces/IVillain';
import { getApiUrl } from '../utilities/PersistenceUtilities';

const URL = `${getApiUrl()}/villains`;

export const getVillains = async (token: string): Promise<IVillain[]> => {
  const response = await fetch(URL, {
    headers: makeAuthHeaders(token),
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}

export const getVillain = async (id: string, token: string): Promise<IVillain> => {
  const response = await fetch(`${URL}/${id}`, {
    headers: makeAuthHeaders(token),
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
};

export const createVillain = async (villain: IVillain, token: string): Promise<IVillain> => {
  const response = await fetch(URL, {
    headers: makeAuthHeaders(token),
    method: "POST",
    body: JSON.stringify(villain),
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}

export const updateVillain = async (villain: IVillain, token: string): Promise<IVillain> => {
  const response = await fetch(`${URL}/${villain.id!}`, {
    headers: makeAuthHeaders(token),
    method: "PUT",
    body: JSON.stringify(villain),
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}

export const deleteVillain = async (id: string, token: string) => {
  const response = await fetch(`${URL}/${id}`, {
    headers: makeAuthHeaders(token),
    method: "DELETE"
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}

export const getRandomVillain = async (exclude: boolean, token: string): Promise<IVillain[]> => {
  const response = await fetch(`${URL}/random?exclude=${exclude}`, {
    headers: makeAuthHeaders(token),
    method: "GET",
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}

export const searchForVillains = async (term: string, token: string): Promise<IVillain[]> => {
  const response = await fetch(`${URL}/search/${term}`, {
    headers: makeAuthHeaders(token),
    method: "GET",
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}
