import React, { ReactElement } from 'react';
import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';
import MainFooter from './common/MainFooter';
import Routes from './common/Routes';
import MainHeader from './common/MainHeader';
import NotificationPill from './common/NotificationPill';
import Auth0ProviderWithHistory from './common/Auth0ProviderWithHistory';

const App: React.FC = (): ReactElement => {
  return (
    <BrowserRouter>
      <RecoilRoot>
        <Auth0ProviderWithHistory>
          <div className="App">
            <MainHeader/>
            <main>
              <Routes/>
            </main>
            <NotificationPill/>
            <MainFooter/>
          </div>
        </Auth0ProviderWithHistory>
      </RecoilRoot>
    </BrowserRouter>
  );
};

export default App;
