import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import permissionAtom from '../atoms/permissionAtom';

const Lists: FC = () => {
  const permissions = useRecoilValue(permissionAtom);
  const isAdmin = permissions.includes('user:admin');
  return (
    <div>
      <ul>
        <li><Link to="/heroes">Heroes</Link></li>
        <li><Link to="/villains">Villains</Link></li>
        <li><Link to="/locations">Locations</Link></li>
        <li><Link to="/challenges">Challenges</Link></li>
        <li><Link to="/collections">Collections</Link></li>
        <li><Link to="/difficulties">Difficulties</Link></li>
        <li><a
          target="_blank"
          href="https://cdn.tstark2.us/randomizer/resources/Marvel_United_Rulebook.pdf"
          rel="noreferrer"
        >Rules (PDF)
        </a></li>
        <li><a
          target="_blank"
          href="https://cdn.tstark2.us/randomizer/resources/Marvel_United_FAQ_2_0.pdf"
          rel="noreferrer"
        >FAQs (PDF)
        </a></li>
        {isAdmin ? <li><Link to="/admin">Admin</Link></li> : undefined}
      </ul>
    </div>
  );
};

export default Lists;
