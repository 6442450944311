export interface IUser {
  id: string;
  collections: string[];
  stats: IUserStats;
}

export interface IUserStats {
  heroesPlayed: string[];
  villainsPlayed: string[];
}

export const blankUserStats: IUserStats = {
  heroesPlayed: [],
  villainsPlayed: [],
}

export const blankUser: IUser = {
  id: '',
  collections: [],
  stats: blankUserStats,
}
