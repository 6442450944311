import React, { FC } from 'react';
import { IHeroicAbility } from '../interfaces/IHero';
import { generateRandomString } from '../utilities/stringUtils';

const HeroicAbility: FC<IHeroicAbilityProps> = ({ability, onAbilityChange }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const updatedAbility = {...ability};

    if (updatedAbility.id === undefined) updatedAbility.id = generateRandomString();

    switch (e.target.id) {
    case 'name':
      updatedAbility.name = e.target.value;
      break;
    case 'text':
      updatedAbility.text = e.target.value;
      break;
    case 'count':
      updatedAbility.count = parseInt(e.target.value);
      break;
    }

    onAbilityChange(updatedAbility);
  }

  return (
    <div className="hero-form__ability-section__form">
      <label>Name:</label>
      <input type="text" id="name" value={ability.name} onChange={handleChange}/>
      <label>Text:</label>
      <textarea id="text" value={ability.text} onChange={handleChange} />
      <label>Count:</label>
      <input type="number" id="count" step={1} min={0} max={10} value={ability.count} onChange={handleChange}/>
    </div>
  );
}

export default HeroicAbility;

interface IHeroicAbilityProps {
  ability: IHeroicAbility;
  onAbilityChange: (ability: IHeroicAbility) => void;
}
