import { INotification, NotificationType } from '../interfaces/INotification';

export const notifyFailure = (title: string, message: string): INotification => ({
  type: NotificationType.FAILURE,
  isOpen: true,
  title,
  message
});

export const notifySuccess = (title: string, message: string): INotification => ({
  type: NotificationType.SUCCESS,
  isOpen: true,
  title,
  message
});
