import { generateRandomString } from '../utilities/stringUtils';

export interface IHero {
  id?: string;
  name: string;
  moves: number;
  heroicActions: number;
  attacks: number;
  wilds: number;
  abilities: IHeroicAbility[];
  collection: string;
}

export interface IHeroicAbility {
  id?: string;
  count: number;
  name: string;
  text: string;
}

export const blankHeroicAbility: IHeroicAbility = {
  id: generateRandomString(),
  count: 0,
  name: '',
  text: '',
}

export const blankHero: IHero = {
  id: '',
  name: '',
  moves: 0,
  heroicActions: 0,
  attacks: 0,
  wilds: 0,
  abilities: [blankHeroicAbility],
  collection: ''
}
