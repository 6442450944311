import React, { FC, useEffect, useState } from 'react';
import { IChallenge } from '../interfaces/IChallenge';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import tokenAtom from '../atoms/tokenAtom';
import { getDifficulties } from '../persistence/DifficultyPersistence';
import { Link } from 'react-router-dom';
import notificationAtom from '../atoms/notificationAtom';
import { NotificationType } from '../interfaces/INotification';

const DifficultyList: FC = () => {
  const [difficulties, setDifficulties] = useState<IChallenge[]>([]);
  const token = useRecoilValue(tokenAtom);
  const setNotification = useSetRecoilState(notificationAtom);

  useEffect(() => {
    getDifficulties(token).then(list => {
      setDifficulties(list);
    }).catch(err => {
      setNotification({
        type: NotificationType.FAILURE,
        isOpen: true,
        title: 'Error getting Difficulties',
        message: err.message
      });
    });
  }, [token, setDifficulties, setNotification]);

  return (
    <div className="challenge-list">
      <ul>
        {difficulties.map(difficulty =>
          <li key={difficulty.id}>
            <Link to={`/difficulties/${difficulty.id}`}>{difficulty.name}</Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default DifficultyList;
