import { ChangeEvent, FC, useState } from 'react';
import { CharacterType } from '../interfaces/CharacterType';
import CharacterSelector from './CharacterSelector';

const SelectHeroes: FC<ISelectHeroesProps> = ({heroes, setHeroes}) => {
  const [playerCount, setPlayerCount] = useState(2);

  const handlePlayerCountChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const newPlayerCount = parseInt(e.target.value);
    setPlayerCount(newPlayerCount);
    if (heroes.length > newPlayerCount) {
      const difference = heroes.length - newPlayerCount;
      const updatedHeroes = [...heroes];
      updatedHeroes.length = updatedHeroes.length - difference;
      setHeroes(updatedHeroes);
    }
  }

  const updateHero = (hero: string, index: number) => {
    const updatedHeroes = [...heroes];
    updatedHeroes[index] = hero;
    setHeroes(updatedHeroes);
  }

  const renderPlayerSections = () => {
    const heroSections = [];
    for (let i = 0; i < playerCount; i++) {
      heroSections.push(
        <CharacterSelector
          character={heroes[i]}
          updateCharacter={updateHero}
          characterType={CharacterType.HERO}
          index={i}
          key={`hero-${i}`}
        />
      );
    }
    return heroSections;
  }

  return (
    <div className="select-heroes">
      <h1>Choose your Heroes</h1>
      <div className="select-heroes__hero-section">
        <label htmlFor="playerCount">Number of Players: ({playerCount})</label>
        <input type="range" min="1" max="4" step="1" name="playerCount" value={playerCount}
               onChange={handlePlayerCountChanged}/>
        <div className="new-game__heroes">
          {renderPlayerSections()}
        </div>
      </div>
    </div>
  );
};

export default SelectHeroes;

interface ISelectHeroesProps {
  heroes: string[];
  setHeroes: (heroes: string[]) => void;
}
