import { makeAuthHeaders } from './AuthHelper';
import { ILocation } from '../interfaces/ILocation';
import { getApiUrl } from '../utilities/PersistenceUtilities';

const URL = `${getApiUrl()}/locations`;

export const getLocations = async (token: string): Promise<ILocation[]> => {
  const response = await fetch(URL, {
    headers: makeAuthHeaders(token),
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
};

export const getLocation = async (id: string, token: string): Promise<ILocation> => {
  const response = await fetch(`${URL}/${id}`, {
    headers: makeAuthHeaders(token),
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}

export const createLocation = async (location: ILocation, token: string): Promise<ILocation> => {
  const response = await fetch(URL, {
    headers: makeAuthHeaders(token),
    method: "POST",
    body: JSON.stringify(location),
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}

export const updateLocation = async (location: ILocation, token: string): Promise<ILocation> => {
  const response = await fetch(`${URL}/${location.id!}`, {
    headers: makeAuthHeaders(token),
    method: "PUT",
    body: JSON.stringify(location),
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}

export const deleteLocation = async (id: string, token: string) => {
  const response = await fetch(`${URL}/${id}`, {
    headers: makeAuthHeaders(token),
    method: "DELETE",
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}

export const getRandomLocations = async (token: string) => {
  const response = await fetch(`${URL}/random`, {
    headers: makeAuthHeaders(token),
    method: "GET"
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}

