import { FC } from 'react';
import { IProtectedRouteProps } from '../interfaces/IProtectedRouteProps';
import { useRecoilValue } from 'recoil';
import permissionAtom from '../atoms/permissionAtom';
import { Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import FourOhFour from './FourOhFour';

const AdminProtectedRoute: FC<IProtectedRouteProps> = (
  {
    component,
    path,
    exact,
    ...args
  }
) => {
  const permissions = useRecoilValue(permissionAtom);
  const isAdmin = permissions.includes('user:admin');

  return (
    <Route
      component={withAuthenticationRequired(isAdmin? component : FourOhFour)}
      path={path}
      exact={exact}
      {...args}
    />
  );
};

export default AdminProtectedRoute;
