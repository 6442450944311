import React, { FC, useReducer } from 'react';
import CollectionSelect from '../common/CollectionSelect';
import { blankHero, blankHeroicAbility, IHero, IHeroicAbility } from '../interfaces/IHero';
import HeroicAbility from './HeroicAbility';
import { generateRandomString } from '../utilities/stringUtils';
import { createHero } from '../persistence/HeroPersistence';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import tokenAtom from '../atoms/tokenAtom';
import notificationAtom from '../atoms/notificationAtom';
import { NotificationType } from '../interfaces/INotification';

const SET_NAME = 'SET_NAME';
const SET_MOVES = 'SET_MOVES';
const SET_HEROIC_ACTIONS = 'SET_HEROIC_ACTIONS';
const SET_ATTACKS = 'SET_ATTACKS';
const SET_WILDS = 'SET_WILDS';
const SET_ABILITIES = 'SET_ABILITIES';
const SET_COLLECTION = 'SET_COLLECTION';
const CLEAR_HERO = 'CLEAR_HERO';

interface ISetName {
  type: typeof SET_NAME;
  name: string;
}

interface ISetMoves {
  type: typeof SET_MOVES;
  moves: number;
}

interface ISetHeroicActions {
  type: typeof SET_HEROIC_ACTIONS;
  heroicActions: number;
}

interface ISetAttacks {
  type: typeof SET_ATTACKS;
  attacks: number;
}

interface ISetWilds {
  type: typeof SET_WILDS;
  wilds: number;
}

interface ISetAbilities {
  type: typeof SET_ABILITIES;
  abilities: IHeroicAbility[];
}

interface ISetCollection {
  type: typeof SET_COLLECTION;
  collection: string;
}

interface IClearHero {
  type: typeof CLEAR_HERO;
}

type HeroFormActions =
  ISetName
  | ISetMoves
  | ISetHeroicActions
  | ISetAttacks
  | ISetWilds
  | ISetAbilities
  | ISetCollection
  | IClearHero;

const reducer = (state: IHero, action: HeroFormActions) => {
  const updatedState = { ...state };
  switch (action.type) {
  case SET_NAME:
    updatedState.name = action.name;
    break;
  case SET_MOVES:
    updatedState.moves = action.moves;
    break;
  case SET_HEROIC_ACTIONS:
    updatedState.heroicActions = action.heroicActions;
    break;
  case SET_ATTACKS:
    updatedState.attacks = action.attacks;
    break;
  case SET_WILDS:
    updatedState.wilds = action.wilds;
    break;
  case SET_ABILITIES:
    updatedState.abilities = action.abilities;
    break;
  case SET_COLLECTION:
    updatedState.collection = action.collection;
    break;
  case CLEAR_HERO:
    updatedState.name = '';
    updatedState.moves = 0;
    updatedState.heroicActions = 0;
    updatedState.attacks = 0;
    updatedState.wilds = 0;
    updatedState.abilities = [blankHeroicAbility];
    updatedState.collection = '';
    break;
  default:
    return updatedState;
  }

  return updatedState;
};

const setName = (name: string): HeroFormActions => ({
  type: SET_NAME,
  name,
});

const setMoves = (moves: number): HeroFormActions => ({
  type: SET_MOVES,
  moves,
});

const setHeroicActions = (heroicActions: number): HeroFormActions => ({
  type: SET_HEROIC_ACTIONS,
  heroicActions,
});

const setAttacks = (attacks: number): HeroFormActions => ({
  type: SET_ATTACKS,
  attacks,
});

const setWilds = (wilds: number): HeroFormActions => ({
  type: SET_WILDS,
  wilds,
});

const setAbilities = (abilities: IHeroicAbility[]): HeroFormActions => ({
  type: SET_ABILITIES,
  abilities,
});

const setCollection = (collection: string): HeroFormActions => ({
  type: SET_COLLECTION,
  collection,
});

const clearHero = (): HeroFormActions => ({
  type: CLEAR_HERO,
});

const HeroForm: FC = () => {
  const [hero, dispatch] = useReducer(reducer, blankHero);
  const token = useRecoilValue(tokenAtom);
  const setNotification = useSetRecoilState(notificationAtom);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    switch (e.target.id) {
    case 'name':
      dispatch(setName(e.target.value));
      break;
    case 'moves':
      dispatch(setMoves(parseInt(e.target.value)));
      break;
    case 'heroicActions':
      dispatch(setHeroicActions(parseInt(e.target.value)));
      break;
    case 'attacks':
      dispatch(setAttacks(parseInt(e.target.value)));
      break;
    case 'wilds':
      dispatch(setWilds(parseInt(e.target.value)));
      break;
    case 'collections':
      dispatch(setCollection(e.target.value));
      break;
    default:
      return;
    }
  };

  const handleAddButtonClick = (e: React.FormEvent) => {
    e.preventDefault();
    const newAbility = blankHeroicAbility;
    newAbility.id = generateRandomString();
    const updatedAbilities = [...hero.abilities];
    updatedAbilities.push(newAbility);
    dispatch(setAbilities(updatedAbilities));
  };

  const handleAbilityChange = (ability: IHeroicAbility) => {
    const updatedAbilities = [...hero.abilities];
    const abilityIndex = updatedAbilities.findIndex(heroicAbility => heroicAbility.id === ability.id);
    if (abilityIndex !== -1) {
      updatedAbilities[abilityIndex] = ability;
      dispatch(setAbilities(updatedAbilities));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    createHero(hero, token).then(() => {
      setNotification({
        type: NotificationType.SUCCESS,
        isOpen: true,
        title: 'Success',
        message: `${hero.name} saved successfully`,
      });
      dispatch(clearHero());
      // history.push(`/heroes/${created.id}`);
    }).catch(err => {
      setNotification({
        type: NotificationType.FAILURE,
        isOpen: true,
        title: 'Error saving hero',
        message: err,
      });
    });
  };

  return (
    <div className="hero-form">
      <h1>Add a Hero</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Name:</label>
        <input type="text" id="name" name="name" onChange={handleChange} value={hero.name}/>
        <section className="stats">
          <div>
            <label htmlFor="moves">Moves:</label>
            <input type="number" id="moves" min="0" step="1" onChange={handleChange} value={hero.moves}/>
          </div>
          <div>
            <label htmlFor="heroicActions">Heroic Actions:</label>
            <input type="number" id="heroicActions" min="0" step="1" onChange={handleChange}
                   value={hero.heroicActions}/>
          </div>
          <div>
            <label htmlFor="attacks">Attacks:</label>
            <input type="number" id="attacks" min="0" step="1" onChange={handleChange} value={hero.attacks}/>
          </div>
          <div>
            <label htmlFor="wilds">Wilds:</label>
            <input type="number" id="wilds" min="0" step="1" onChange={handleChange} value={hero.wilds}/>
          </div>
        </section>
        <CollectionSelect onChange={handleChange} id="collections" value={hero.collection}/>
        <section className="hero-form__ability-section">
          <header>
            <h3>Heroic Abilities</h3>
            <button onClick={handleAddButtonClick}>Add</button>
          </header>
        </section>
        {hero.abilities.map(ability => <HeroicAbility key={ability.id} ability={ability}
                                                      onAbilityChange={handleAbilityChange}/>)}
        <button>Save</button>
      </form>
    </div>
  );
};

export default HeroForm;
