import React, { FC, useState } from 'react';
import CollectionSelect from '../common/CollectionSelect';
import { createLocation } from '../persistence/LocationPersistence';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import tokenAtom from '../atoms/tokenAtom';
import { blankLocation, ILocation } from '../interfaces/ILocation';
import notificationAtom from '../atoms/notificationAtom';
import { NotificationType } from '../interfaces/INotification';

const LocationForm: FC = () => {
  const [location, setLocation] = useState<ILocation>(blankLocation);
  const token = useRecoilValue(tokenAtom);
  const setNotification = useSetRecoilState(notificationAtom);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await createLocation(location, token);
      setNotification({
        type: NotificationType.SUCCESS,
        isOpen: true,
        title: 'Success!',
        message: `${location.name} saved successfully`,
      });
      setLocation(blankLocation);
    } catch (err) {
      setNotification({
        type: NotificationType.FAILURE,
        isOpen: true,
        title: 'Error saving location',
        message: err,
      });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const updatedLocation = {...location};

    switch (e.target.id) {
    case "name":
      updatedLocation.name = e.target.value;
      break;
    case "endOfTurn":
      updatedLocation.endOfTurn = e.target.value;
      break;
    case "collection":
      updatedLocation.collection = e.target.value;
      break;
    default:
      return;
    }

    setLocation(updatedLocation);
  };

  return (
    <form className="location-form" onSubmit={handleSubmit}>
      <h1>Add a Location</h1>
      <label htmlFor="name">Name:</label>
      <input type="text" id="name" value={location.name} onChange={handleChange}/>
      <label htmlFor="endOfTurn">End of Turn Effect:</label>
      <textarea id="endOfTurn" value={location.endOfTurn} onChange={handleChange} />
      <CollectionSelect onChange={handleChange} id="collection" value={location.collection}/>
      <button>Save</button>
    </form>
  );
};

export default LocationForm;
