export interface IVillain {
  id?: string;
  name: string;
  bam: string;
  overflow: string;
  plot: string;
  health: {
    twoPlayers: number;
    threePlayers: number;
    fourPlayers: number;
  };
  threats: IVillainThreat[];
  specialRules?: string;
  collection: string;
}

export interface IVillainThreat {
  id?: string;
  count: number;
  name: string;
  text: string;
  health?: number;
  hasBam: boolean;
}

export const blankVillainThreat: IVillainThreat = {
  id: '',
  count: 0,
  name: '',
  text: '',
  health: 0,
  hasBam: false,
}

export const blankVillain: IVillain = {
  id: '',
  name: '',
  bam: '',
  overflow: '',
  plot: '',
  health: {
    twoPlayers: 0,
    threePlayers: 0,
    fourPlayers: 0,
  },
  threats: [blankVillainThreat],
  collection: '',
}
