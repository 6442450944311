import { FC } from 'react';
import { Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { IProtectedRouteProps } from '../interfaces/IProtectedRouteProps';

const ProtectedRoute: FC<IProtectedRouteProps> = (
  {
    component,
    path,
    exact,
    ...args
  }) => (
  <Route
    component={withAuthenticationRequired(component)}
    path={path}
    exact={exact}
    {...args}
  />
);

export default ProtectedRoute;
