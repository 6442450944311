import { makeAuthHeaders } from './AuthHelper';
import { IUser, IUserStats } from '../interfaces/IUser';
import { getApiUrl } from '../utilities/PersistenceUtilities';

const URL = `${getApiUrl()}/users`;

export const getUser = async (id: string, token: string): Promise<IUser> => {
  const response = await fetch(`${URL}/${id}`, {
    headers: makeAuthHeaders(token)
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}

export const getUserFromApi = async (token: string): Promise<IUser> => {
  const response = await fetch(`${URL}/login`, {
    headers: makeAuthHeaders(token),
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}

export const updateUser = async (user: IUser, token: string): Promise<IUser> => {
  const response = await fetch(`${URL}/${user.id}`, {
    headers: makeAuthHeaders(token),
    method: "PUT",
    body: JSON.stringify(user)
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}

export const updateStats = async (stats: IUserStats, token: string): Promise<IUser> => {
  const response = await fetch(`${URL}/me`, {
    headers: makeAuthHeaders(token),
    method: "PATCH",
    body: JSON.stringify(stats),
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}
