import { isEmpty } from 'lodash';

export const generateRandomString = (): string => {
  const alphabet = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.split('');
  const randomString = [];
  for(let i = 0; i < 8; i++) {
    const random = Math.floor(Math.random() * alphabet.length + 1);
    randomString.push(alphabet[random]);
  }
  return randomString.join('');
}

export const convertToFilename = (name: string): string => {
  if (!isEmpty(name)) {
    return name.replaceAll(' ', '_').replaceAll('.', '');
  }

  return '';
}
