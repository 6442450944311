import React, { FC, useState } from 'react';
import CollectionSelect from '../common/CollectionSelect';
import { createChallenge } from '../persistence/ChallengePersistence';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import tokenAtom from '../atoms/tokenAtom';
import { blankChallenge, IChallenge } from '../interfaces/IChallenge';
import notificationAtom from '../atoms/notificationAtom';
import { NotificationType } from '../interfaces/INotification';

const ChallengeForm: FC = () => {
  const [ challenge, setChallenge ] = useState<IChallenge>(blankChallenge);
  const token = useRecoilValue(tokenAtom);
  const setNotification = useSetRecoilState(notificationAtom);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await createChallenge(challenge, token);
      setNotification({
        type: NotificationType.SUCCESS,
        isOpen: true,
        title: 'Success!',
        message: `${challenge.name} saved successfully`
      });
      setChallenge(blankChallenge);
    } catch (err) {
      setNotification({
        type: NotificationType.FAILURE,
        isOpen: true,
        title: 'Error saving challenge',
        message: err,
      });
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const updatedChallenge = {...challenge};
    switch (e.target.id) {
    case "name":
      updatedChallenge.name = e.target.value;
      break;
    case "setup":
      updatedChallenge.setup = e.target.value;
      break;
    case "collection":
      updatedChallenge.collection = e.target.value;
      break;
    default:
      return;
    }

    setChallenge(updatedChallenge);
  }

  return (
    <form className="challenge-form" onSubmit={handleSubmit}>
      <h1>Add a Challenge</h1>
      <label htmlFor="name">Name:</label>
      <input type="text" id="name" value={challenge.name} onChange={handleChange}/>
      <label htmlFor="setup">Setup:</label>
      <textarea id="setup" value={challenge.setup} onChange={handleChange} />
      <CollectionSelect onChange={handleChange} id="collection" value={challenge.collection} />
      <button>Save</button>
    </form>
  );
};

export default ChallengeForm
