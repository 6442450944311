import React, { FC, useEffect, useState } from 'react';
import { ILocation } from '../interfaces/ILocation';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import tokenAtom from '../atoms/tokenAtom';
import { getLocations } from '../persistence/LocationPersistence';
import { Link } from 'react-router-dom';
import notificationAtom from '../atoms/notificationAtom';
import { NotificationType } from '../interfaces/INotification';

const LocationList: FC = () => {
  const [locations, setLocations] = useState<ILocation[]>([]);
  const token = useRecoilValue(tokenAtom);
  const setNotification = useSetRecoilState(notificationAtom);

  useEffect(() => {
    getLocations(token).then(list => {
      setLocations(list);
    }).catch(err => {
      setNotification({
        type: NotificationType.FAILURE,
        isOpen: true,
        title: 'Error getting Locations',
        message: err.message,
      });
    });
  }, [token, setLocations, setNotification]);

  const renderLocations = () => locations.map(location => (
    <li key={location.id}><Link to={`/locations/${location.id}`}>{location.name}</Link></li>
  ));

  return (
    <div className="location-list">
      <ul>
        {renderLocations()}
      </ul>
    </div>
  );
};

export default LocationList;
