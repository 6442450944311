import { makeAuthHeaders } from './AuthHelper';
import { IChallenge } from '../interfaces/IChallenge';
import { getApiUrl } from '../utilities/PersistenceUtilities';

const URL = `${getApiUrl()}/challenges`;

export const getChallenges = async (token: string): Promise<IChallenge[]> => {
  const response = await fetch(URL, {
    headers: makeAuthHeaders(token),
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
};

export const getChallenge = async (id: string, token: string): Promise<IChallenge> => {
  const response = await fetch(`${URL}/${id}`, {
    headers: makeAuthHeaders(token),
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}

export const createChallenge = async (challenge: IChallenge, token: string): Promise<IChallenge> => {
  const response = await fetch(URL, {
    headers: makeAuthHeaders(token),
    method: "POST",
    body: JSON.stringify(challenge),
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}

export const updateChallenge = async (challenge: IChallenge, token: string): Promise<IChallenge> => {
  const response = await fetch(`${URL}/${challenge.id!}`, {
    headers: makeAuthHeaders(token),
    method: "PUT",
    body: JSON.stringify(challenge),
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}

export const deleteChallenge = async (id: string, token: string) => {
  const response = await fetch(`${URL}/${id}`, {
    headers: makeAuthHeaders(token),
    method: "DELETE",
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}

export const getRandomChallenge = async (token: string): Promise<IChallenge[]> => {
  const response = await fetch(`${URL}/random`, {
    headers: makeAuthHeaders(token),
    method: "GET",
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}

