import React, { FC } from 'react';

const ToggleSwitch: FC<IToggleSwitchProps> = ({ checked, onChange, name }) => (
    <label className="toggle-switch">
      <input id={name} type="checkbox" name={name} checked={checked} onChange={onChange}/>
      <span className="slider"/>
    </label>
  );

export default ToggleSwitch;

interface IToggleSwitchProps {
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;

}
