import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

const Auth0ProviderWithHistory: FC = ({children}) => {
  // this data is not private, it can be shared publicly
  const DOMAIN = 'tstark2.us.auth0.com';
  const CLIENT_ID = 'CKqzmoJQtO5wSD9IJSzxgUCq2ziFJfeG';
  const history = useHistory();

  const onRedirectCallback = (appState: any) => {
    history.push(appState?.returnTo || window.location.pathname);
  }

  return (
    <Auth0Provider
      domain={DOMAIN}
      clientId={CLIENT_ID}
      redirectUri={`${window.location.origin}/authorized`}
      onRedirectCallback={onRedirectCallback}
      audience="https://randomizer.tstark2.us"
      scope="user:standard"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
