import React, { FC, useState } from 'react';
import CollectionSelect from '../common/CollectionSelect';
import { createDifficulty } from '../persistence/DifficultyPersistence';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import tokenAtom from '../atoms/tokenAtom';
import { blankChallenge, IChallenge } from '../interfaces/IChallenge';
import notificationAtom from '../atoms/notificationAtom';
import { NotificationType } from '../interfaces/INotification';

const DifficultyForm: FC = () => {
  const [ difficulty, setDifficulty ] = useState<IChallenge>(blankChallenge);
  const token = useRecoilValue(tokenAtom);
  const setNotification = useSetRecoilState(notificationAtom);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await createDifficulty(difficulty, token);
      setNotification({
        type: NotificationType.SUCCESS,
        isOpen: true,
        title: 'Success!',
        message: `${difficulty.name} saved successfully`
      });
      setDifficulty(blankChallenge);
    } catch (err) {
      setNotification({
        type: NotificationType.FAILURE,
        isOpen: true,
        title: 'Error saving difficulty',
        message: err,
      });
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const updatedDifficulty = {...difficulty};
    switch (e.target.id) {
    case "name":
      updatedDifficulty.name = e.target.value;
      break;
    case "setup":
      updatedDifficulty.setup = e.target.value;
      break;
    case "collection":
      updatedDifficulty.collection = e.target.value;
      break;
    default:
      return;
    }

    setDifficulty(updatedDifficulty);
  }

  return (
    <form className="difficulty-form" onSubmit={handleSubmit}>
      <h1>Add a Difficulty</h1>
      <label htmlFor="name">Name:</label>
      <input type="text" id="name" value={difficulty.name} onChange={handleChange}/>
      <label htmlFor="setup">Setup:</label>
      <textarea id="setup" value={difficulty.setup} onChange={handleChange} />
      <CollectionSelect onChange={handleChange} id="collection" value={difficulty.collection} />
      <button>Save</button>
    </form>
  );
};

export default DifficultyForm;
