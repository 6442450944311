import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import IPathParams from '../interfaces/IPathParams';
import { blankCollection, ICollection } from '../interfaces/ICollection';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import tokenAtom from '../atoms/tokenAtom';
import { getCollection } from '../persistence/CollectionPersistence';
import notificationAtom from '../atoms/notificationAtom';
import { NotificationType } from '../interfaces/INotification';
import { convertToFilename } from '../utilities/stringUtils';
import { isEmpty } from 'lodash';

const Collection: FC = () => {
  const { id } = useParams<IPathParams>();
  const [ collection, setCollection ] = useState<ICollection>(blankCollection);
  const token = useRecoilValue(tokenAtom);
  const setNotification = useSetRecoilState(notificationAtom);

  useEffect(() => {
    getCollection(id, token).then(fetched => {
      setCollection(fetched);
    }).catch(err => {
      setNotification({
        type: NotificationType.FAILURE,
        isOpen: true,
        title: 'Error getting Collection',
        message: err.message,
      });
    });
  }, [token, id, setNotification, setCollection]);

  return (
    <div className="collection-page">
      <h2>{collection.name}</h2>
      {!isEmpty(collection.name) ?
        <img
          src={`https://cdn.tstark2.us/randomizer/collections/${convertToFilename(collection.name)}.jpeg`}
          alt={collection.name}
        />
        : undefined
      }
    </div>
  )
}

export default Collection;
