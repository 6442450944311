import { makeAuthHeaders } from './AuthHelper';
import { IHero } from '../interfaces/IHero';
import { getApiUrl } from '../utilities/PersistenceUtilities';

const URL = `${getApiUrl()}/heroes`;

export const getHeroes = async (token: string): Promise<IHero[]> => {
  const response = await fetch(URL, {
    headers: makeAuthHeaders(token),
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);

}

export const getHero = async (id: string, token: string): Promise<IHero> => {
  const response = await fetch(`${URL}/${id}`, {
    headers: makeAuthHeaders(token),
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
};

export const createHero = async (hero: IHero, token: string): Promise<IHero> => {
  const response = await fetch(URL, {
    headers: makeAuthHeaders(token),
    method: "POST",
    body: JSON.stringify(hero),
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}

export const updateHero = async (hero: IHero, token: string): Promise<IHero> => {
  const response = await fetch(`${URL}/${hero.id}`, {
    headers: makeAuthHeaders(token),
    method: "PUT",
    body: JSON.stringify(hero),
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}

export const deleteHero = async (id: string, token: string): Promise<IHero> => {
  const response = await fetch(`${URL}/${id}`, {
    headers: makeAuthHeaders(token),
    method: "DELETE",
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}

export const getRandomHero = async (exclude: boolean, token: string): Promise<IHero[]> => {
  const response = await fetch(`${URL}/random?exclude=${exclude}`, {
    headers: makeAuthHeaders(token),
    method: "GET",
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}

export const searchForHeroes = async (term: string, token: string): Promise<IHero[]> => {
  const response = await fetch(`${URL}/search/${term}`, {
    headers: makeAuthHeaders(token),
    method: "GET",
  });

  if (response.ok) {
    return await response.json();
  }

  const errorText = await response.text();
  throw new Error(errorText);
}
