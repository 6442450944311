import React, { FC, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { getRandomLocations } from '../persistence/LocationPersistence';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import tokenAtom from '../atoms/tokenAtom';
import { NotificationType } from '../interfaces/INotification';
import notificationAtom from '../atoms/notificationAtom';
import { ILocation } from '../interfaces/ILocation';

const NewGameSummary: FC<INewGameSummaryProps> = (
  {
    heroes,
    villain,
    challenge,
    difficulty,
  }
) => {
  const [locations, setLocations] = useState<string[]>([]);
  const token = useRecoilValue(tokenAtom);
  const setNotification = useSetRecoilState(notificationAtom);

  useEffect(() => {
    const getLocations = () => {
      getRandomLocations(token).then(locs => {
        setLocations(locs.map((loc: ILocation) => loc.name));
      }).catch(err => {
       setNotification({
          type: NotificationType.FAILURE,
          isOpen: true,
          title: 'Error getting Locations',
          message: err.message,
        })
      });
    };
    if (isEmpty(locations)) {
      getLocations();
    }
  }, [locations, setNotification, token]);

  const renderHeroes = () => (
    heroes.map(hero => (
      <dd key={hero}>{hero}</dd>
    ))
  );

  const renderChallenge = () => {
    if (!isEmpty(challenge) && challenge !== 'Ooops, you don\'t seem to have any challenges...') {
      return (
        <>
          <dt>Challenge:</dt>
          <dd>{challenge}</dd>
        </>
      );
    }
    return undefined;
  };

  const renderDifficulty = () => {
    if (!isEmpty(difficulty)) {
      return (
        <>
          <dt>Difficulty:</dt>
          <dd>{difficulty}</dd>
        </>
      );
    }
    return undefined;
  };

  const renderLocations = () => {
    if (!isEmpty(locations)) {
      return (
        <>
          <dt>Locations:</dt>
          {locations.map(loc => <dd key={loc}>{loc}</dd>)}
        </>
      );
    }
    return undefined;
  }

  return (
    <div className="new-game-summary">
      <h1>Summary</h1>
      <dl>
        <dt>Heroes:</dt>
        {renderHeroes()}
        <dt>Villain:</dt>
        <dd>{villain}</dd>
        {renderLocations()}
        {renderChallenge()}
        {renderDifficulty()}
      </dl>
      <Link to="/log-play">Go Play!</Link>
    </div>
  );
};

export default NewGameSummary;

interface INewGameSummaryProps {
  heroes: string[];
  villain: string;
  challenge: string;
  difficulty: string;
}
