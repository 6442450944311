import { FC, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import notificationAtom from '../atoms/notificationAtom';
import { blankNotification } from '../interfaces/INotification';

const NotificationPill: FC = () => {
  const [notification, setNotification ] = useRecoilState(notificationAtom);
  const [displayClass, setDisplayClass] = useState('hide');

  useEffect(() => {
    if (notification.isOpen) {
      setDisplayClass('show');
      setTimeout(() => {
        setDisplayClass('hide');
        setTimeout(() => {
          setNotification(blankNotification);
        }, 4000);
      }, (3000));
    }
  }, [notification.isOpen, setNotification]);

  return (
    <div className={`notification-pillbox notification-${displayClass} notification-${notification.type}`}>
      <div className="notification-pill">
        <p className="title">{notification.title}</p>
        <p className="message">{notification.message}</p>
      </div>
    </div>
  );
};

export default NotificationPill;
