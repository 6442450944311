import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getHero } from '../persistence/HeroPersistence';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import tokenAtom from '../atoms/tokenAtom';
import { blankHero, IHero } from '../interfaces/IHero';
import IPathParams from '../interfaces/IPathParams';
import { NotificationType } from '../interfaces/INotification';
import notificationAtom from '../atoms/notificationAtom';
import { CDN_URL } from '../common/Constants';
import { convertToFilename } from '../utilities/stringUtils';

const Hero: FC = () => {
  const { id } = useParams<IPathParams>();
  const [ hero, setHero ] = useState<IHero>(blankHero);
  const token = useRecoilValue(tokenAtom);
  const setNotification = useSetRecoilState(notificationAtom);

  useEffect(() => {
    getHero(id, token).then(fetched => {
      setHero(fetched);
    }).catch(err => {
      setNotification({
        type: NotificationType.FAILURE,
        isOpen: true,
        title: 'Error getting Hero',
        message: err.message,
      });
    });
  }, [token, id, setHero, setNotification]);

  const renderAbilities = () => hero.abilities.map(ability => (
    <div key={ability.name}>
      <h4>{ability.name}</h4>
      <p>{ability.text}</p>
      <p><strong>Count: </strong>{ability.count}</p>
    </div>
  ));
  return (
    <div className="hero-page">
      <h2>{hero.name}</h2>
      <img src={`${CDN_URL}/heroes/${convertToFilename(hero.name)}.jpeg`} alt=""/>
      <ul>
        <li>Moves: {hero.moves}</li>
        <li>Heroic Actions: {hero.heroicActions}</li>
        <li>Attacks: {hero.attacks}</li>
        <li>Wilds: {hero.wilds}</li>
      </ul>
      <h3>Abilities:</h3>
      {renderAbilities()}
      <p>Collection: {hero.collection}</p>
    </div>
  );
};

export default Hero;
