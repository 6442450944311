import { FC } from 'react';
import { CharacterType } from '../interfaces/CharacterType';
import CharacterSelector from './CharacterSelector';

const SelectVillain: FC<ISelectVillainProps> = ({ villain, updateVillain, heroes }) => {
  return (
    <div className="select-villain">
      <h1>Choose your Villain</h1>
      <div className="select-villain__villain">
        <CharacterSelector
          character={villain}
          updateCharacter={updateVillain}
          characterType={CharacterType.VILLAIN}
          index={0}
          exclude={heroes}
        />
      </div>
    </div>
  );
};

export default SelectVillain;

interface ISelectVillainProps {
  villain: string;
  updateVillain: (villain: string) => void;
  heroes: string[];
}
