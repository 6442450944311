import React, { FC, useState } from 'react';
import { ICollection } from '../interfaces/ICollection';
import { createCollection } from '../persistence/CollectionPersistence';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import tokenAtom from '../atoms/tokenAtom';
import notificationAtom from '../atoms/notificationAtom';
import { NotificationType } from '../interfaces/INotification';

const CollectionForm: FC = () => {
  const [ name, setName ] = useState('');
  const token = useRecoilValue(tokenAtom);
  const setNotification = useSetRecoilState(notificationAtom);

  const saveCollection = async (e: React.FormEvent) => {
    e.preventDefault();
    const newCollection: ICollection = {
      name: name,
    }

    try {
      await createCollection(newCollection, token)
      setNotification({
        type: NotificationType.SUCCESS,
        isOpen: true,
        title: 'Success!',
        message: `${newCollection.name} saved successfully`,
      });
      setName('');
    } catch (err) {
      setNotification({
        type: NotificationType.FAILURE,
        isOpen: true,
        title: 'Error saving collection',
        message: err,
      });
    }
  }

  return (
    <form className='collection-form' onSubmit={saveCollection}>
      <h1>Add a Collection</h1>
      <label htmlFor="name">Name:</label>
      <input type="text" id="name" value={name} onChange={e => setName(e.target.value)}/>
      <button>Save</button>
    </form>
  );
};

export default CollectionForm;
