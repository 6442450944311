import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import IPathParams from '../interfaces/IPathParams';
import { blankLocation, ILocation } from '../interfaces/ILocation';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import tokenAtom from '../atoms/tokenAtom';
import { getLocation } from '../persistence/LocationPersistence';
import notificationAtom from '../atoms/notificationAtom';
import { NotificationType } from '../interfaces/INotification';

const Location: FC = () => {
  const { id } = useParams<IPathParams>();
  const [location, setLocation] = useState<ILocation>(blankLocation);
  const token = useRecoilValue(tokenAtom);
  const setNotification = useSetRecoilState(notificationAtom);

  useEffect(() => {
    getLocation(id, token).then(fetched => {
      setLocation(fetched);
    }).catch(err => {
      setNotification({
        type: NotificationType.FAILURE,
        isOpen: true,
        title: 'Error getting Location',
        message: err.message,
      });
    });
  }, [id, token, setLocation, setNotification]);

  return (
    <div className="location-page">
      <h2>{location.name}</h2>
      <h3>End of Turn Effect:</h3>
      <p>{location.endOfTurn}</p>
      <h3>Collection:</h3>
      <p>{location.collection}</p>
    </div>
  );

}

export default Location;
