import React, { FC, FormEvent, useState } from 'react';
import CharacterSearch from '../common/CharacterSearch';
import { CharacterType } from '../interfaces/CharacterType';
import { cloneDeep, isEmpty } from 'lodash';
import { updateUser } from '../persistence/UserPersistence';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import userAtom from '../atoms/userAtom';
import notificationAtom from '../atoms/notificationAtom';
import tokenAtom from '../atoms/tokenAtom';
import { notifyFailure, notifySuccess } from '../utilities/NotificationUtilities';
import { Link } from 'react-router-dom';

const LogPlay: FC = () => {
  const [hero, setHero] = useState<string>('');
  const [villain, setVillain] = useState<string>('');
  const [user, setUser] = useRecoilState(userAtom);
  const setNotification = useSetRecoilState(notificationAtom);
  const token = useRecoilValue(tokenAtom);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    let changed = false;
    const updatedUser = cloneDeep(user);
    if (!updatedUser.stats.heroesPlayed.includes(hero)) {
      updatedUser.stats.heroesPlayed.push(hero);
      changed = true;
    }
    if (!updatedUser.stats.villainsPlayed.includes(villain)) {
      updatedUser.stats.villainsPlayed.push(villain);
      changed = true;
    }

    if (changed) {
      updateUser(updatedUser, token)
      .then(updated => {
        setUser(updated);
        setNotification(notifySuccess('Success!', 'Play logged successfully'));
        setHero('');
        setVillain('');
      })
      .catch(err => {
        setNotification(notifyFailure('Error logging play', err.message));
      });
    } else {
      setHero('');
      setVillain('');
    }

  }

  if (isEmpty(user.collections)) {
    return (
      <div className="log-play">
        <p>You don't seem to have any collections. You must add a collection before you can log a play.</p>
        <Link to='/profile/collections'>Update my Collections</Link>
      </div>
    )
  }

  return (
    <div className="log-play">
      <h1>Log a Played Game</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="hero">Which Hero were you?</label>
        <CharacterSearch characterType={CharacterType.HERO} character={hero} updateCharacter={setHero}/>
        <label htmlFor="villain">Which Villain did you face?</label>
        <CharacterSearch characterType={CharacterType.VILLAIN} character={villain} updateCharacter={setVillain}/>
        <div className="log-play__buttons">
          <button disabled={isEmpty(hero) || isEmpty(villain)}>Save</button>
        </div>
      </form>
    </div>
  );
}

export default LogPlay;
