import React, { FC, useEffect, useState } from 'react';
import { ICollection } from '../interfaces/ICollection';
import { useRecoilValue } from 'recoil';
import tokenAtom from '../atoms/tokenAtom';
import { getCollections } from '../persistence/CollectionPersistence';

const CollectionSelect: FC<ICollectionSelectProps> = ({ onChange, id, value }) => {
  const [collections, setCollections] = useState<ICollection[]>([]);
  const token = useRecoilValue(tokenAtom);

  useEffect(() => {
    const listCollections = async () => {
      try {
        const fetched = await getCollections(token);
        setCollections(fetched);
      } catch (err) {
        console.error(err);
      }
    };

    listCollections().catch();
  }, [token]);

  const renderOptions = () => collections.map(collection => (
    <option key={collection.id!} value={collection.name}>{collection.name}</option>
  ));

  return (
    <>
      <label htmlFor={id}>Collection:</label>
      <select className="collection-select" id={id} onChange={onChange} value={value}>
        <option value="">Select One</option>
        {renderOptions()}
      </select>
    </>
  );
};

export default CollectionSelect;

interface ICollectionSelectProps {
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  id: string;
  value?: string;
}
