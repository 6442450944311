import React, { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useRecoilValue } from 'recoil';
import userAtom from '../atoms/userAtom';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { convertToFilename } from '../utilities/stringUtils';

const Profile: FC = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const user = useRecoilValue(userAtom);

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (!isAuthenticated) {
    return <div>Who are you?</div>
  }

  const renderList = (list: string[]) => {
    if (isEmpty(list)) return undefined;

    const items = list.map(item => <li key={item}>{item}</li>);
    return <ul>{items}</ul>
  }

  const renderCollections = (list: string[]) => {
    if (isEmpty(list)) return undefined;
    return (
      <div className="profile__collections">
        {list.map(collection => (
          <img
            src={`https://cdn.tstark2.us/randomizer/collections/${convertToFilename(collection)}.jpeg`}
            alt={collection}
          />
          )
        )}
      </div>
    );
  }

  return (
      <div>
        <h1>My Profile</h1>
        <details open>
          <summary>Heroes I've Played as</summary>
          {isEmpty(user.stats.heroesPlayed) ? <div className="emptyList">You haven't played as any heroes yet... </div>
            : renderList(user.stats.heroesPlayed)
          }
        </details>
        <details open>
          <summary>Villains I've Played against</summary>
          {isEmpty(user.stats.villainsPlayed) ? <div className="emptyList">You haven't played against any villains yet...</div>
            : renderList(user.stats.villainsPlayed)
          }
        </details>
        <details open>
          <summary>Collections I Own</summary>
          {renderCollections(user.collections)}
          <Link to="/profile/collections">Update my Collections</Link>
        </details>
      </div>
  );
};

export default Profile;
