import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import tokenAtom from '../atoms/tokenAtom';
import { blankVillain, IVillain } from '../interfaces/IVillain';
import { getVillain } from '../persistence/VillainPersistence';
import { NotificationType } from '../interfaces/INotification';
import notificationAtom from '../atoms/notificationAtom';
import { CDN_URL } from '../common/Constants';
import { convertToFilename } from '../utilities/stringUtils';

const Villain: FC = () => {
  const { id } = useParams<IVillainParams>();
  const [villain, setVillain] = useState<IVillain>(blankVillain);
  const token = useRecoilValue(tokenAtom);
  const setNotification = useSetRecoilState(notificationAtom);

  useEffect(() => {
    getVillain(id, token).then(fetched => {
      setVillain(fetched);
    }).catch(err => {
      setNotification({
        type: NotificationType.FAILURE,
        isOpen: true,
        title: 'Error getting Villain',
        message: err,
      });
    });
  }, [id, token, setNotification, setVillain]);

  const renderThreats = () => villain.threats.map(threat => (
    <div key={threat.name}>
      <h4>{threat.name}</h4>
      <p>{threat.text}</p>
      {threat.hasBam ? <p>BAM!</p> : undefined}
      {threat.health !== undefined ? <p><strong>Health:</strong>{threat.health}</p> : undefined}
    </div>
  ));

  const renderSpecialRules = () => {
    if (villain.specialRules === undefined) return undefined;

    return (
      <>
        <h3>Special Rules:</h3>
        <p>{villain.specialRules}</p>
      </>
    );
  };

  return (
    <div className="villain-page">
      <h2>{villain.name}</h2>
      <img src={`${CDN_URL}/villains/${convertToFilename(villain.name)}.jpeg`} alt=""/>
      <h3>BAM Effect:</h3>
      <p>{villain.bam}</p>
      <h3>Overflow Effect:</h3>
      <p>{villain.overflow}</p>
      <h3>Villainous Plot:</h3>
      <p>{villain.plot}</p>
      <h3>Health:</h3>
      <table>
        <thead>
        <tr>
          <th>Number Of Players</th>
          <th>Health</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>2</td>
          <td>{villain.health.twoPlayers}</td>
        </tr>
        <tr>
          <td>3</td>
          <td>{villain.health.threePlayers}</td>
        </tr>
        <tr>
          <td>4</td>
          <td>{villain.health.fourPlayers}</td>
        </tr>
        </tbody>
      </table>
      <h3>Threats:</h3>
      {renderThreats()}
      {renderSpecialRules()}
      <h3>Collection:</h3>
      <p>{villain.collection}</p>
    </div>
  );
};

export default Villain;

interface IVillainParams {
  id: string;
}
