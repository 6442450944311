import { FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import { CharacterType } from '../interfaces/CharacterType';
import { isEmpty } from 'lodash';
import { CDN_URL } from '../common/Constants';
import { convertToFilename } from '../utilities/stringUtils';
import { ReactComponent as RandomIcon } from '../static/icons/random.svg';
import { getRandomHero } from '../persistence/HeroPersistence';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import tokenAtom from '../atoms/tokenAtom';
import notificationAtom from '../atoms/notificationAtom';
import { NotificationType } from '../interfaces/INotification';
import { getRandomVillain } from '../persistence/VillainPersistence';
import RecycleToggle from '../common/RecycleToggle';

const CharacterSelector: FC<ICharacterSelectorProps> = (
  {
    character,
    updateCharacter,
    characterType,
    index
  }
) => {
  const [recycleChars, setRecycleChars] = useState(true);
  const token = useRecoilValue(tokenAtom);
  const setNotification = useSetRecoilState(notificationAtom);

  const getCharacterImage = () => {
    if (!isEmpty(character)) {
      switch (characterType) {
      case CharacterType.HERO:
        return `${CDN_URL}/heroes/${convertToFilename(character)}.jpeg`;
      case CharacterType.VILLAIN:
        return `${CDN_URL}/villains/${convertToFilename(character)}.jpeg`;
      }
    }
    return '';
  };

  const getHero = useCallback(() => {
    getRandomHero(!recycleChars, token).then(fetched => {
      updateCharacter(fetched[0].name, index);
    }).catch(err => {
      setNotification({
        type: NotificationType.FAILURE,
        isOpen: true,
        title: 'Error getting Hero',
        message: err.message,
      });
    });
  }, [updateCharacter, index, setNotification, recycleChars, token]);

  const getVillain = useCallback(() => {
    getRandomVillain(!recycleChars, token).then(fetched => {
      updateCharacter(fetched[0].name, 0)
    }).catch(err => {
      setNotification({
        type: NotificationType.FAILURE,
        isOpen: true,
        title: 'Error getting Villain',
        message: err.message,
      });
    });
  }, [updateCharacter, setNotification, recycleChars, token]);

  const getNewCharacter = () => {
    if (characterType === CharacterType.VILLAIN) {
      getVillain();
    } else {
      getHero();
    }
  };

  const handleRandomClick = (e: MouseEvent) => {
    e.preventDefault();
    getNewCharacter();
  };

  const handleRecycleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setRecycleChars(!recycleChars);
  }

  useEffect(() => {
    if (isEmpty(character)) {
      if (characterType === CharacterType.HERO) {
        getHero();
      } else {
        getVillain();
      }
    }
  }, [getHero, getVillain, characterType, character]);

  return (
    <div className="character-selector">
      <img src={getCharacterImage()} alt={character}/>
      <p>{character}</p>
      <div className="character-selector__buttons">
        <RecycleToggle isChecked={recycleChars} onClick={handleRecycleClick} />
        <button onClick={handleRandomClick}><RandomIcon /></button>
      </div>
    </div>
  );
};

export default CharacterSelector;

interface ICharacterSelectorProps {
  character: string;
  updateCharacter: (character: string, index: number) => void;
  characterType: CharacterType;
  index: number;
  exclude?: string[];
}
