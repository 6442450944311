import React, { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Home: FC = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div>
      <h1>Marvel United Randomizer</h1>
      <p>
        Marvel United is a massive game. There are hundreds of you could play as, and a high number of villains to play against.
        This app helps you keep track of which characters you've already played
        and set up new games with characters you haven't used yet.
      </p>
      <button
        onClick={() => loginWithRedirect({screen_hint: 'signup'})}
      >Sign Up Now!</button>
      <p>Already have an account?</p>
      <button
        onClick={() => loginWithRedirect()}
      >Log In</button>


    </div>
  );
};

export default Home
