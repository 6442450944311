export interface ICollection {
  id?: string;
  name: string;
}

export const blankCollection: ICollection = {
  id: '',
  name: ''
}


