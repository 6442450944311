import React, { FC, useEffect, useState } from 'react';
import { IVillain } from '../interfaces/IVillain';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import tokenAtom from '../atoms/tokenAtom';
import { getVillains } from '../persistence/VillainPersistence';
import { Link } from 'react-router-dom';
import { NotificationType } from '../interfaces/INotification';
import notificationAtom from '../atoms/notificationAtom';

const VillainList: FC = () => {
  const [villains, setVillains] = useState<IVillain[]>([]);
  const token = useRecoilValue(tokenAtom);
  const setNotification = useSetRecoilState(notificationAtom);

  useEffect(() => {
    getVillains(token).then(list => {
      setVillains(list);
    }).catch(err => {
      setNotification({
        type: NotificationType.FAILURE,
        isOpen: true,
        title: 'Error loading collections',
        message: err,
      });
    });
  }, [token, setNotification, setVillains]);

  const renderVillains = () => {
    return villains.map(villain => (
      <li key={villain.id}><Link to={`/villains/${villain.id}`}>{villain.name}</Link></li>
    ));
  };

  return (
    <div className="villain-list">
      <ul>
        {renderVillains()}
      </ul>
    </div>
  );
};

export default VillainList;
