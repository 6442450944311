import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import tokenAtom from '../atoms/tokenAtom';
import { IHero } from '../interfaces/IHero';
import notificationAtom from '../atoms/notificationAtom';
import { getHeroes } from '../persistence/HeroPersistence';
import { NotificationType } from '../interfaces/INotification';

const HeroList: FC = () => {
  const [heroes, setHeroes] = useState<IHero[]>([]);
  const token = useRecoilValue(tokenAtom);
  const setNotification = useSetRecoilState(notificationAtom);

  useEffect(() => {
    getHeroes(token).then(list => {
      setHeroes(list);
    }).catch(err => {
      setNotification({
        type: NotificationType.FAILURE,
        isOpen: true,
        title: 'Error getting Heroes',
        message: err.message,
      });
    });
  }, [token, setNotification, setHeroes]);

  const renderHeroes = () => {
    return heroes.map(hero => (
      <li key={hero.id}><Link to={`/heroes/${hero.id}`}>{hero.name}</Link></li>
    ));
  }

  return (
    <div className="hero-list">
      <ul>
        {renderHeroes()}
      </ul>
    </div>
  );
};

export default HeroList;
