import React, { FC, useState } from 'react';
import SelectHeroes from './SelectHeroes';
import SelectVillain from './SelectVillain';
import SelectChallenge from './SelectChallenge';
import SelectDifficulty from './SelectDifficulty';
import NewGameSummary from './NewGameSummary';
import { ReactComponent as NextIcon } from '../static/icons/chevron-right.svg';
import { intersection, isEmpty } from 'lodash';
import { COLLECTIONS_WITH_CHALLENGES } from '../common/Constants';
import { useRecoilValue } from 'recoil';
import userAtom from '../atoms/userAtom';
import { blankChallenge, IChallenge } from '../interfaces/IChallenge';
import { Link } from 'react-router-dom';

const NewGame: FC = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [heroes, setHeroes] = useState<string[]>([]);
  const [villain, setVillain] = useState('');
  const [challenge, setChallenge] = useState<IChallenge>(blankChallenge);
  const [difficulty, setDifficulty] = useState<IChallenge>(blankChallenge);
  const user = useRecoilValue(userAtom);
  const hasChallenges = intersection(user.collections, COLLECTIONS_WITH_CHALLENGES).length > 0;

  const handleNextClick = () => {
    if (currentPage < 5) {
      if (currentPage === 1 && !hasChallenges) {
        setCurrentPage(3);
      } else {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  const renderPanel = () => {
    switch (currentPage) {
    case 0:
      return <SelectHeroes heroes={heroes} setHeroes={setHeroes}/>;
    case 1:
      return <SelectVillain villain={villain} updateVillain={setVillain} heroes={heroes}/>;
    case 2:
      return <SelectChallenge challenge={challenge} updateChallenge={setChallenge}/>;
    case 3:
      return <SelectDifficulty difficulty={difficulty} updateDifficulty={setDifficulty}/>;
    case 4:
      return <NewGameSummary heroes={heroes} villain={villain} challenge={challenge.name} difficulty={difficulty.name}/>;
    default:
      return <h1>Nothing Selected</h1>;
    }
  };

  const nextDisabled = () => {
    const heroSet = new Set(heroes);
    return heroSet.size !== heroes.length;
  };

  const renderButtons = () => {
    if (currentPage < 4) {
      return (
        <div className="new-game__buttons">
          <button
            name="next"
            onClick={handleNextClick}
            disabled={nextDisabled()}
          >
            <NextIcon/>
          </button>
        </div>
      );
    }
  };

  if (isEmpty(user.collections)) {
    return (
      <div className="new-game">
        <div className="new-game__content">
          <p>You don't seem to have any collections. You must add a collection before creating a game.</p>
          <Link to='/profile/collections'>Update my Collections</Link>
        </div>
      </div>
    );
  }

  return (
    <div className="new-game">
      <div className="new-game__content">
        {renderPanel()}
      </div>
      {renderButtons()}
    </div>
  );
};

export default NewGame;
