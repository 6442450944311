export interface IChallenge {
  id?: string;
  name: string;
  setup: string;
  collection: string;
}

export const blankChallenge: IChallenge = {
  id: '',
  name: '',
  setup: '',
  collection: '',
}
