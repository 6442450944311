import React, { FC, useReducer } from 'react';
import CollectionSelect from '../common/CollectionSelect';
import { blankVillain, blankVillainThreat, IVillain, IVillainThreat } from '../interfaces/IVillain';
import VillainThreatSection from './VillainThreatSection';
import { generateRandomString } from '../utilities/stringUtils';
import { createVillain } from '../persistence/VillainPersistence';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import tokenAtom from '../atoms/tokenAtom';
import notificationAtom from '../atoms/notificationAtom';
import { NotificationType } from '../interfaces/INotification';

const SET_NAME = 'SET_NAME';
const SET_BAM = 'SET_BAM';
const SET_OVERFLOW = 'SET_OVERFLOW';
const SET_PLOT = 'SET_PLOT';
const SET_HEALTH = 'SET_HEALTH';
const SET_THREATS = 'SET_THREATS';
const SET_SPECIAL_RULES = 'SET_SPECIAL_RULES';
const SET_COLLECTION = 'SET_COLLECTION';
const CLEAR_VILLAIN = 'CLEAR_VILLAIN';

interface ISetName {
  type: typeof SET_NAME;
  name: string;
}

interface ISetBam {
  type: typeof SET_BAM;
  bam: string;
}

interface ISetOverflow {
  type: typeof SET_OVERFLOW;
  overflow: string;
}

interface ISetPlot {
  type: typeof SET_PLOT;
  plot: string;
}

interface ISetHealth {
  type: typeof SET_HEALTH;
  players: number;
  health: number;
}

interface ISetThreats {
  type: typeof SET_THREATS;
  threats: IVillainThreat[];
}

interface ISetSpecialRules {
  type: typeof SET_SPECIAL_RULES;
  rules: string;
}

interface ISetCollection {
  type: typeof SET_COLLECTION;
  collection: string;
}

interface IClearVillain {
  type: typeof CLEAR_VILLAIN;
}

type VillainFormAction =
  ISetName
  | ISetBam
  | ISetOverflow
  | ISetPlot
  | ISetHealth
  | ISetThreats
  | ISetSpecialRules
  | ISetCollection
  | IClearVillain;

const reducer = (state: IVillain, action: VillainFormAction) => {
  const updatedState = { ...state };
  switch (action.type) {
  case SET_NAME:
    updatedState.name = action.name;
    break;
  case SET_BAM:
    updatedState.bam = action.bam;
    break;
  case SET_OVERFLOW:
    updatedState.overflow = action.overflow;
    break;
  case SET_PLOT:
    updatedState.plot = action.plot;
    break;
  case SET_HEALTH:
    switch (action.players) {
    case 2:
      updatedState.health.twoPlayers = action.health;
      break;
    case 3:
      updatedState.health.threePlayers = action.health;
      break;
    case 4:
      updatedState.health.fourPlayers = action.health;
      break;
    }
    break;
  case SET_THREATS:
    updatedState.threats = action.threats;
    break;
  case SET_SPECIAL_RULES:
    updatedState.specialRules = action.rules;
    break;
  case SET_COLLECTION:
    updatedState.collection = action.collection;
    break;
  case CLEAR_VILLAIN:
    updatedState.name = '';
    updatedState.bam = '';
    updatedState.overflow = '';
    updatedState.plot = '';
    updatedState.health.twoPlayers = 0;
    updatedState.health.threePlayers = 0;
    updatedState.health.fourPlayers = 0;
    updatedState.threats = [blankVillainThreat];
    updatedState.collection = '';
    updatedState.specialRules = '';
    break;
  default:
    return updatedState;
  }

  return updatedState;
};

const setName = (name: string): VillainFormAction => ({
  type: SET_NAME,
  name,
});

const setBam = (bam: string): VillainFormAction => ({
  type: SET_BAM,
  bam,
});

const setOverflow = (overflow: string): VillainFormAction => ({
  type: SET_OVERFLOW,
  overflow,
});

const setPlot = (plot: string): VillainFormAction => ({
  type: SET_PLOT,
  plot,
});

const setHealth = (players: number, health: number): VillainFormAction => ({
  type: SET_HEALTH,
  players,
  health,
});

const setThreats = (threats: IVillainThreat[]): VillainFormAction => ({
  type: SET_THREATS,
  threats,
});

const setSpecialRules = (rules: string): VillainFormAction => ({
  type: SET_SPECIAL_RULES,
  rules,
});

const setCollection = (collection: string): VillainFormAction => ({
  type: SET_COLLECTION,
  collection,
});

const clearVillain = (): VillainFormAction => ({
  type: CLEAR_VILLAIN,
});


const VillainForm: FC = () => {
  const [villain, dispatch] = useReducer(reducer, blankVillain);
  const token = useRecoilValue(tokenAtom);
  const setNotification = useSetRecoilState(notificationAtom);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    createVillain(villain, token).then(() => {
      setNotification({
        type: NotificationType.SUCCESS,
        isOpen: true,
        title: 'Success!',
        message: `${villain.name} saved successfully!`,
      });
      dispatch(clearVillain());
    }).catch(err => {
      setNotification({
        type: NotificationType.FAILURE,
        isOpen: true,
        title: 'Error saving villain',
        message: err,
      });
    });
  };

  const handleThreatChanged = (threat: IVillainThreat) => {
    const updatedThreats = [...villain.threats];
    let threatIndex = updatedThreats.findIndex(thisThreat => thisThreat.id === threat.id);
    if (threatIndex === -1) {
      threatIndex = updatedThreats.findIndex(thisThreat => thisThreat.id === '');
    }
    updatedThreats[threatIndex] = threat;
    dispatch(setThreats(updatedThreats));
  };

  const handleAddThreatClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const updatedThreats = [...villain.threats];
    const newThreat = blankVillainThreat;
    newThreat.id = generateRandomString();
    updatedThreats.push(newThreat);
    dispatch(setThreats(updatedThreats));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    switch (e.target.id) {
    case 'name':
      dispatch(setName(e.target.value));
      break;
    case 'bam':
      dispatch(setBam(e.target.value));
      break;
    case 'overflow':
      dispatch(setOverflow(e.target.value));
      break;
    case 'plot':
      dispatch(setPlot(e.target.value));
      break;
    case 'twoPlayers':
      dispatch(setHealth(2, parseInt(e.target.value)));
      break;
    case 'threePlayers':
      dispatch(setHealth(3, parseInt(e.target.value)));
      break;
    case 'fourPlayers':
      dispatch(setHealth(4, parseInt(e.target.value)));
      break;
    case 'specialRules':
      dispatch(setSpecialRules(e.target.value));
      break;
    case 'collection':
      dispatch(setCollection(e.target.value));
      break;
    default:
      return;
    }
  };

  return (
    <div className="villain-form">
      <h1>Add a Villain</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Name:</label>
        <input type="text" id="name" value={villain.name} onChange={handleChange}/>
        <label htmlFor="bam">Bam Effect:</label>
        <textarea id="bam" value={villain.bam} onChange={handleChange}/>
        <label htmlFor="overflow">Overflow Effect:</label>
        <textarea id="overflow" value={villain.overflow} onChange={handleChange}/>
        <label htmlFor="plot">Villainous Plot:</label>
        <textarea id="plot" value={villain.plot} onChange={handleChange}/>
        <div className="healthForm">
          <div>
            <label htmlFor="twoPlayers">Two Player Health:</label>
            <input type="number" id="twoPlayers" value={villain.health.twoPlayers} onChange={handleChange} min="0"
                   step="1"/>
          </div>
          <div>
            <label htmlFor="threePlayers">Three Player Health:</label>
            <input type="number" id="threePlayers" value={villain.health.threePlayers} onChange={handleChange} min="0"
                   step="1"/>
          </div>
          <div>
            <label htmlFor="fourPlayers">Four Player Health:</label>
            <input type="number" id="fourPlayers" value={villain.health.fourPlayers} onChange={handleChange} min="0"
                   step="1"/>
          </div>
        </div>
        <label htmlFor="specialRules">Special Rules:</label>
        <textarea id="specialRules" value={villain.specialRules} onChange={handleChange}/>
        <CollectionSelect onChange={handleChange} id="collection" value={villain.collection}/>
        <section className="villain-form__threat-section">
          <header>
            <h3>Threats</h3>
            <button onClick={handleAddThreatClick}>Add</button>
          </header>
          {villain.threats.map(threat => <VillainThreatSection
            threat={threat}
            onThreatChanged={handleThreatChanged}
            key={`villain-threat-section-${threat.id}`}
          />)}
        </section>
        <button>Save</button>
      </form>
    </div>
  );
};

export default VillainForm;
