import React, { FC, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import tokenAtom from '../atoms/tokenAtom';
import { useHistory } from 'react-router-dom';
import userAtom from '../atoms/userAtom';
import { blankUser } from '../interfaces/IUser';

const Logout: FC = () => {
  const setToken = useSetRecoilState(tokenAtom);
  const setUser = useSetRecoilState(userAtom);
  const history = useHistory();

  useEffect(() => {
    setToken('');
    setUser(blankUser);
    history.push('/');
  }, [setToken, history, setUser]);

  return (
    <div>Logging you out...</div>
  );
};

export default Logout;
